<template>
	<div id="adoptEdit">
		<page-adopt-contract is-shop="0"></page-adopt-contract>
	</div>
</template>

<script>
	import pageAdoptContract from '@/components/layout/adopt/page-adopt-contract.vue'
	export default{
		components:{
			pageAdoptContract
		},
	}
</script>

<style>
</style>
