<template>
    <div class="adopt-contract">
        <div class="el-content mb12">
            <a-space>
                <a-input-search
                    v-model:value="acState.search.key"
                    style="width:300px"
                    placeholder="请输入合同名称进行查询"
                    enter-button
                    @search="getAdoptContractList(1,acState.limit)"
                />
                <router-link to="/adopt/contract_edit">
                    <a-button type="primary"><i class="ri-add-line"></i>新增合同</a-button>
                </router-link>
            </a-space>
        </div>
        <div class="el-content">
            <a-table :pagination="false" row-key="id" :data-source="acState.list" :columns="[
                {title:'ID',dataIndex:'id'},
                {title:'合同名称',dataIndex:'name'},
                {title:'甲方',dataIndex:'jia',slots:{customRender:'jia'}},
                {title:'乙方',dataIndex:'party_b'},
                {title:'排序',dataIndex:'sort'},
                {title:'修改时间',dataIndex:'update_time'},
                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            ]">
                <template #jia="{record}">
                    <div v-for="(item,index) in record.owner" :key="index">
                        {{item.key}} : {{item.value}}
                    </div>
                </template>
                <template #action="{record}">
                    <a-space>
                        <router-link :to="{path:'/adopt/contract_edit',query:{id:record.id}}">
                            <kd-button type="primary" icon="ri-edit-line" title="编辑"></kd-button>
                        </router-link>
                        <kd-button type="success"
                            icon="ri-file-list-3-line"
                            title="合同记录"
                            @click="showContractRecord(record.id)">
                        </kd-button>
                    </a-space>
                </template>
            </a-table>
            <div class="pager">
				<a-pagination
					show-size-changer
					:default-current="acState.page"
					:total="acState.count"
					@showSizeChange="(p,e)=>{getAdoptContractList(acState.page,e)}"
					@change="(e)=>{getAdoptContractList(e,acState.limit)}"
				/>
			</div>
        </div>

        <a-modal title="合同记录"
                 width="900px"
                 v-model:visible="acrState.show"
                 :footer="null"
                 @cancel="acrState.show= false"
        >
            <a-table :pagination="false" row-key="id" :data-source="acrState.list" :columns="[
                {title:'ID',dataIndex:'id'},
                {title:'签订项目',dataIndex:'name'},
                {title:'签订对象',dataIndex:'user',slots:{customRender:'user'}},
                {title:'签订时间',dataIndex:'create_time'},
                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            ]">
                <template #user="{record}">
                    {{record.nickname}}（{{record.mobile}}）
                </template>
                <template #action="{record}">
                    <a-space>
                        <kd-button type="success"
                            icon="ri-download-line"
                            title="下载合同"
                            @click="downloadContract(record.order_id)">
                        </kd-button>
                    </a-space>
                </template>
            </a-table>
            <div class="pager">
				<a-pagination
					show-size-changer
					:default-current="acrState.page"
					:total="acrState.count"
					@showSizeChange="(p,e)=>{getContractRecord(acrState.page,e)}"
					@change="(e)=>{getContractRecord(e,acrState.limit)}"
				/>
			</div>
        </a-modal>
    </div>
</template>
<script>
import { useAdoptContract,useAdoptContractRecord } from '@/models/adopt'
export default {
    setup() {
        let { acState ,getAdoptContractList } = useAdoptContract()
        let acrState = useAdoptContractRecord()

        getAdoptContractList(1,acState.limit)

        return{
            acState ,getAdoptContractList,
            ...acrState
        }
    },
}
</script>
